import moment from 'moment';
import Vue from 'vue';
import { Store } from 'vuex';

import store from '../../core/store';
import { UPDATE_AUTH, USER_LOGOUT } from '../../core/store/modules/coreModule';
import notificationsApi, { NotificationDto } from '../api/notifications';
import { NotificationTypes } from '../constants/notifications';
import {
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '../store/notifications';
import { INotificationsService } from './types';

interface NotificationsState {
  /**
   * List of notifications
   */
  notifications: NotificationDto[];
  /**
   * The last date the notifications were fetched
   */
  lastFetchDate: string | null;
  /**
   * Toggle to show the notifications. Only forces showing, doesn't
   * necessarily reflect actual notifications state
   */
  showNotifications: boolean;
}

/**
 * Default poll delay 5mn
 */
const DEFAULT_POLL_DELAY = 5 * 60 * 1000;

/**
 * Fast poll delay 10s
 */
const FAST_POLL_DELAY = 10 * 1000;

/**
 * Notifications Service
 */
export class NotificationsService implements INotificationsService {
  /**
   * Poll timeout
   */
  private pollTimeout: number | null = null;

  /**
   * Reactive notifications state
   */
  private state: NotificationsState = Vue.observable(this.getDefaultState());

  /**
   * @param store - vuex store
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private readonly store: Store<any>) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (store.state.auth.isLoggedIn) {
      void this.pollNotifications();
    }

    store.subscribe((mutation) => {
      if (mutation.type === UPDATE_AUTH && mutation.payload.isLoggedIn) {
        void this.pollNotifications();
      }
    });
    store.subscribeAction((action) => {
      if (action.type === USER_LOGOUT && this.pollTimeout !== null) {
        clearTimeout(this.pollTimeout);
        this.state = this.getDefaultState();
      }
    });
  }

  /**
   * @inheritdoc
   */
  public async pollNotifications(): Promise<void> {
    if (this.pollTimeout !== null) {
      clearTimeout(this.pollTimeout);
    }

    const activeNotifications = await notificationsApi.getActiveNotifications();
    this.state.lastFetchDate = new Date(Date.now()).toISOString();

    if (!this.state.showNotifications || this.state.notifications.length <= 0) {
      // If the notifications are not being shown, simply use the api state.
      this.state.notifications = activeNotifications;
    } else {
      // If the notifications are being shown, we cannot simply use the api state
      // because expired notifications would just disappear from the list.
      // Add/update the list with the api state
      const uniqueNotificationsDict = [
        ...this.state.notifications,
        // Spread the api notifications after the current state so that updated notifications will
        // overwrite the current ones
        ...activeNotifications,
      ].reduce((acc, notif) => {
        acc[notif.id] = notif;
        return acc;
      }, {} as Record<string, NotificationDto>);

      // Get the notifications as array and sort them since our old notifs and active notifs were merged
      const allNotifications = Object.values(uniqueNotificationsDict).sort((a, b) => {
        const aDate = moment(a.updatedDate);
        const bDate = moment(b.updatedDate);
        if (aDate.isSame(bDate)) {
          return 0;
        }

        return aDate.isBefore(bDate) ? -1 : 1;
      });

      this.state.notifications = allNotifications;
    }

    let delay = DEFAULT_POLL_DELAY;
    if (this.state.notifications.find((n) => n.type === NotificationTypes.PROGRESS)) {
      delay = FAST_POLL_DELAY;
    }

    this.pollTimeout = window.setTimeout(() => {
      void this.pollNotifications();
    }, delay);
  }

  /**
   * @inheritdoc
   */
  public async setNotificationsRead(): Promise<void> {
    await notificationsApi.setNotificationsRead(this.state.lastFetchDate);
  }

  /**
   * @inheritdoc
   */
  public getNotifications(): NotificationDto[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return [...this.state.notifications, ...(store.getters.notifications as NotificationDto[])];
  }

  /**
   * @inheritdoc
   */
  public getShowNotifications(): boolean {
    return this.state.showNotifications;
  }

  /**
   * @inheritdoc
   */
  public setShowNotifications(value: boolean): void {
    this.state.showNotifications = value;
  }

  /**
   * @inheritdoc
   */
  public async createNotification(componentName: string, data: any): Promise<string> {
    const notificationId = (await store.dispatch(CREATE_NOTIFICATION, {
      componentName,
      data,
    })) as Promise<string>;

    return notificationId;
  }

  /**
   * @inheritdoc
   */
  public updateNotification(notificationId: string, data: any): void {
    store.commit(UPDATE_NOTIFICATION, { id: notificationId, data });
  }

  /**
   * @inheritdoc
   */
  public removeNotification(notificationId: string): void {
    this.store.commit(REMOVE_NOTIFICATION, notificationId);
    const idx = this.state.notifications.findIndex((n) => n.id === notificationId);
    if (idx !== -1) {
      this.state.notifications.splice(idx, 1);
    }
  }

  /**
   * @returns default notifications state
   */
  private getDefaultState(): NotificationsState {
    return {
      notifications: [],
      lastFetchDate: null,
      showNotifications: false,
    };
  }
}
