import Vue from 'vue';

import { ResolvedResponse } from '../../core/types/http';

/**
 * Applications API.
 */
const API_URL_BASE = '/api/piivo/v1/notifications';

export interface NotificationDto {
  id: string;
  type: string;
  title: string;
  content: string;
  progress: number;
  actionId: string;
  users: string[];
  userProperties: Record<string, string>;
  userGroups: string[];
  startDate: string;
  endDate: string;
  updatedDate: string;
}

export default {
  /**
   * Get active notifications.
   * @returns all active notifications.
   */
  async getActiveNotifications(): Promise<NotificationDto[]> {
    const res = (await Vue.http.get(API_URL_BASE)) as ResolvedResponse<NotificationDto[]>;
    return res.body;
  },

  /**
   * Sets the notifications read date to now
   *
   * @param readDate - the date to set as read date
   */
  async setNotificationsRead(readDate: string | null): Promise<void> {
    (await Vue.http.post(`${API_URL_BASE}/read`, null, {
      params: { readDate },
    })) as ResolvedResponse<void>;
  },
};
