<template>
  <header id="pui-header">
    <pui-dialog
      ref="confirmLogoutDialog"
      :showCloseButton="false"
      class="logout-dialog"
      transition="slide-up"
    >
      <h3 class="message">
        <span class="mdi mdi-logout message-logout__container">{{
          $t('platform.disconnect.confirmation.message')
        }}</span>
      </h3>
      <pui-button-list>
        <pui-button variant="secondary" class="btn-outline" @click="cancelLogout">
          {{ $t('platform.disconnect.confirmation.cancel') }}
        </pui-button>
        <pui-button v-track:click="Tracking.AUTH_LOGOUT" variant="primary" @click="confirmLogout">
          {{ $t('platform.disconnect.confirmation.confirm') }}
        </pui-button>
      </pui-button-list>
    </pui-dialog>

    <slot name="logo">
      <router-link :to="{ name: 'applications' }" class="logo-link">
        <pui-image :src="imgLogoHeader" class="logo" alt="PiiVO" />
      </router-link>
    </slot>
    <div class="header__content">
      <slot />
    </div>

    <pui-header-button-separator />
    <pui-header-button-list class="header-topInformation">
      <pui-popper
        ref="accountPopper"
        class="pui-header__account-actions-popper-root"
        :options="{ placement: 'bottom-end' }"
        popperClass="pui-header__account-actions-popper"
      >
        <template #reference>
          <pui-header-button
            :title="user.displayName"
            picto="mdi-account-circle"
            class="user-name-button"
          >
            {{ user.displayName }}
          </pui-header-button>
        </template>

        <template #default>
          <pui-flex direction="column" class="pui-header__account-actions-popper-content">
            <slot name="account"></slot>

            <pui-flex
              direction="column"
              class="pui-header__account-actions"
              @click="closeAccountPopper"
            >
              <pui-button
                picto="mdi-power"
                class="pui-header__account-action-button"
                variant="secondary"
                flat
                @click="askLogout"
              >
                <a class="pui-header__account-action-link">
                  <span class="pui-header__account-action-picto mdi" />
                  {{ $t('platform.header.disconnect') }}
                </a>
              </pui-button>
              <pui-button
                v-if="helpUrl != null"
                picto="mdi-help-circle-outline"
                class="pui-header__account-action-button help"
                variant="secondary"
                flat
              >
                <a
                  v-track:click="Tracking.MENU_GO_TO_HELP"
                  :href="helpUrl"
                  class="pui-header__account-action-link"
                  target="_blank"
                >
                  <span class="pui-header__account-action-picto mdi" />
                  {{ $t('platform.header.help') }}
                </a>
              </pui-button>
            </pui-flex>
          </pui-flex>
        </template>
      </pui-popper>
    </pui-header-button-list>
  </header>
</template>
<script>
import { mapGetters } from 'vuex';

import services from '../../core/services';
import Tracking from '../constants/tracking';
import imgLogoHeader from '../static/img/piivo-logo-connexion.svg';

export default {
  name: 'PuiHeader',
  data() {
    return {
      // Expose tracking constants
      Tracking: Tracking,
      imgLogoHeader,
      accountPopperIsOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    /**
     * Current application documentation url.
     */
    helpUrl() {
      const currentApplicationSettings = services.getService('applications').getCurrentApp();
      return currentApplicationSettings ? currentApplicationSettings.documentationUrl : null;
    },
  },
  methods: {
    askLogout() {
      this.$refs.confirmLogoutDialog.open();
    },
    confirmLogout() {
      services.getService('auth').logout();
    },
    cancelLogout() {
      this.$refs.confirmLogoutDialog.close();
    },
    /**
     * Closes the account popper
     *
     * @returns {void}
     */
    closeAccountPopper() {
      if (this.$refs.accountPopper) {
        this.$refs.accountPopper.close();
      }
    },
  },
};
</script>
