<template>
  <pui-flex class="plat-notification-item plat-notification-item__root" direction="column" flex="1">
    <pui-flex
      :class="{
        'plat-notification-item__title': true,
        [`notification-${data.type.toLowerCase()}`]: !!data.type,
      }"
    >
      <div class="notif-title-main">
        <span
          :class="{
            'notif-title-main__picto mdi': true,
            'mdi-clock-outline': data.type === NotificationTypes.PROGRESS,
            'mdi-alert-circle-outline': data.type === NotificationTypes.ERROR,
            'mdi-check-circle-outline': data.type === NotificationTypes.SUCCESS,
            'mdi-bullhorn-outline': data.type === NotificationTypes.ANNOUNCEMENT,
          }"
        ></span>
        <!-- {{ $t(`poster.creation.creation_tasks.notifications.in_progress.${data.action}.title`) }} -->
        {{ data.title }}
      </div>
      <div v-if="data.type !== NotificationTypes.PROGRESS" class="notif-title-secondary">
        <pui-button picto="mdi-close" flat variant="secondary" @click.stop="dismissNotification" />
      </div>
    </pui-flex>

    <div class="plat-notification-item__content">
      <span v-html="data.content"></span>

      <template v-if="data.type === NotificationTypes.PROGRESS">
        <pui-common-progress-linear v-if="displayProgress === -1" hideMessage />
        <div v-else>
          <span>{{
            $t('poster.creation.creation_tasks.notifications.in_progress.download.progress', {
              progress: displayProgress,
            })
          }}</span>
          <pui-common-progress-linear hideMessage :value="data.progress" determinate />
        </div>
      </template>
    </div>
  </pui-flex>
</template>

<script>
import services from '../../../core/services';
import { NotificationTypes } from '../../constants/notifications';

export default {
  name: 'PlatNotificationItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      NotificationTypes,
    };
  },
  computed: {
    /**
     * @returns {number} the display progress
     */
    displayProgress() {
      if (this.data.type !== NotificationTypes.PROGRESS) {
        return -1;
      }

      // Round progress except between 99-100
      return this.data.progress > 99 && this.data.progress < 100
        ? 99
        : Math.round(this.data.progress);
    },
  },
  methods: {
    /**
     * Dismisses the notification
     */
    dismissNotification() {
      services.getService('notifications').removeNotification(this.data.id);
    },
  },
};
</script>
