<template>
  <span>
    <pui-popper
      ref="popper"
      v-bind="$props"
      :options="computedOptions"
      popperClass="pui-notifications-popper"
      @open="onNotificationsOpened"
      @close="onNotificationsClosed"
    >
      <div class="pui-notifications">
        <pui-flex :class="headerClass" alignItems="center">
          {{ $t('platform.notifications.title') }}
          <pui-button flat variant="secondary" class="header-button-hide" @click="hide">
            {{ $t('platform.notifications.hide') }}
          </pui-button>
        </pui-flex>
        <pui-flex class="pui-notifications-list" direction="column">
          <template v-for="notification in notifications">
            <template v-if="notification.__old__">
              <component
                :is="notification.componentName"
                :id="notification.id"
                :key="notification.id"
                :data="notification.data"
                @openDialog="openDialog"
              />
            </template>
            <template v-else>
              <plat-notification-item
                :key="notification.id"
                :data="notification"
              ></plat-notification-item>
            </template>
          </template>
        </pui-flex>
      </div>
    </pui-popper>
    <component
      :is="dialogComponent"
      v-if="dialogComponent"
      ref="dialog"
      v-bind="dialogData"
      @close="onCloseDialog"
    />
  </span>
</template>

<script>
import puiPopper from 'piivo-ui/src/components/popper/Popper';

import services from '../../../core/services';

const defaultOptions = {
  placement: 'bottom-end',
};

export default {
  name: 'PuiNotifications',
  props: {
    ...puiPopper.props,
  },
  data() {
    return {
      /** If the notifications are open */
      isOpen: false,
      dialogComponent: null,
      dialogData: {},
    };
  },
  computed: {
    /**
     * @returns {object[]} array of current notifications
     */
    notifications() {
      return services.getService('notifications').getNotifications();
    },
    /**
     * @returns {boolean} if an external service needs the notifications to be opened
     */
    showNotifications() {
      return services.getService('notifications').getShowNotifications();
    },
    /**
     * @returns {object} class object for the header
     */
    headerClass() {
      return {
        'pui-notifications-header': true,
        showBottomBorder: !!this.notifications.length,
      };
    },
    /**
     * @returns {object} prop options merged with defaults
     */
    computedOptions() {
      return {
        ...defaultOptions,
        ...this.options,
      };
    },
  },
  watch: {
    /**
     * Watches showNotifications value to trigger open
     *
     * @param {boolean} nextValue - next value
     * @param {boolean} prevValue - previous value
     */
    showNotifications(nextValue) {
      if (nextValue && !this.isOpen) {
        this.open();
      } else if (!nextValue && this.isOpen) {
        this.hide();
      }
    },
  },
  methods: {
    /**
     * Opens the popper
     *
     * @returns {void}
     */
    open() {
      this.$refs.popper.open();
    },
    /**
     * Closes the popper
     *
     * @returns {void}
     */
    hide() {
      this.$refs.popper.close();
    },
    /**
     * Handles notifications opened
     */
    onNotificationsOpened() {
      this.isOpen = true;
      services.getService('notifications').setShowNotifications(true);
      services.getService('notifications').setNotificationsRead();
    },
    /**
     * Handles notifications closed
     */
    onNotificationsClosed() {
      this.isOpen = true;
      services.getService('notifications').setShowNotifications(false);
    },
    /**
     * Sets the current dialog component to the one
     * in parameter
     *
     * @param {string} dialogComponent - the name of the component
     * to render
     * @param {object} dialogData - object of props to pass to the
     * component
     */
    openDialog(dialogComponent, dialogData) {
      this.dialogComponent = dialogComponent;
      this.dialogData = dialogData;
    },
    /**
     * Clears the current dialog component
     *
     * @returns {void}
     */
    onCloseDialog() {
      this.dialogComponent = null;
      this.dialogData = {};
    },
  },
};
</script>
