var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pui-flex',{staticClass:"plat-notification-item plat-notification-item__root",attrs:{"direction":"column","flex":"1"}},[_c('pui-flex',{class:( _obj = {
      'plat-notification-item__title': true
    }, _obj[("notification-" + (_vm.data.type.toLowerCase()))] = !!_vm.data.type, _obj )},[_c('div',{staticClass:"notif-title-main"},[_c('span',{class:{
          'notif-title-main__picto mdi': true,
          'mdi-clock-outline': _vm.data.type === _vm.NotificationTypes.PROGRESS,
          'mdi-alert-circle-outline': _vm.data.type === _vm.NotificationTypes.ERROR,
          'mdi-check-circle-outline': _vm.data.type === _vm.NotificationTypes.SUCCESS,
          'mdi-bullhorn-outline': _vm.data.type === _vm.NotificationTypes.ANNOUNCEMENT,
        }}),_vm._v(" "+_vm._s(_vm.data.title)+" ")]),(_vm.data.type !== _vm.NotificationTypes.PROGRESS)?_c('div',{staticClass:"notif-title-secondary"},[_c('pui-button',{attrs:{"picto":"mdi-close","flat":"","variant":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.dismissNotification($event)}}})],1):_vm._e()]),_c('div',{staticClass:"plat-notification-item__content"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.content)}}),(_vm.data.type === _vm.NotificationTypes.PROGRESS)?[(_vm.displayProgress === -1)?_c('pui-common-progress-linear',{attrs:{"hideMessage":""}}):_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('poster.creation.creation_tasks.notifications.in_progress.download.progress', { progress: _vm.displayProgress, })))]),_c('pui-common-progress-linear',{attrs:{"hideMessage":"","value":_vm.data.progress,"determinate":""}})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }